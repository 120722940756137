@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {
	:root {
		--background: 0 0% 100%;
		--foreground: 222.2 84% 4.9%;
		--card: 0 0% 100%;
		--card-foreground: 222.2 84% 4.9%;
		--popover: 0 0% 100%;
		--popover-foreground: 222.2 84% 4.9%;
		--secondary: 210 40% 96.1%;
		--secondary-foreground: 222.2 47.4% 11.2%;
		--muted: 210 40% 96.1%;
		--muted-foreground: 215.4 16.3% 46.9%;
		--accent: 210 40% 96.1%;
		--accent-foreground: 222.2 47.4% 11.2%;
		--destructive: 0 84.2% 60.2%;
		--destructive-foreground: 210 40% 98%;
		--border: 214.3 31.8% 91.4%;
		--input: 214.3 31.8% 91.4%;
		--ring: 222.2 84% 4.9%;
		--radius: 0.5rem;
		--chart-1: 12 76% 61%;
		--chart-2: 173 58% 39%;
		--chart-3: 197 37% 24%;
		--chart-4: 43 74% 66%;
		--chart-5: 27 87% 67%;
	}

	.dark {
		--background: 222.2 84% 4.9%;
		--foreground: 210 40% 98%;
		--card: 222.2 84% 4.9%;
		--card-foreground: 210 40% 98%;
		--popover: 222.2 84% 4.9%;
		--popover-foreground: 210 40% 98%;
		--secondary: 217.2 32.6% 17.5%;
		--secondary-foreground: 210 40% 98%;
		--muted: 217.2 32.6% 17.5%;
		--muted-foreground: 215 20.2% 65.1%;
		--accent: 217.2 32.6% 17.5%;
		--accent-foreground: 210 40% 98%;
		--destructive: 0 62.8% 30.6%;
		--destructive-foreground: 210 40% 98%;
		--border: 217.2 32.6% 17.5%;
		--input: 217.2 32.6% 17.5%;
		--ring: 212.7 26.8% 83.9%;
		--chart-1: 220 70% 50%;
		--chart-2: 160 60% 45%;
		--chart-3: 30 80% 55%;
		--chart-4: 280 65% 60%;
		--chart-5: 340 75% 55%;
	}
}

@layer base {
	* {
		@apply border-border;
	}

	body {
		@apply bg-background text-foreground;
	}
}

@layer utilities {
	.heading-stroke {
		-webkit-text-stroke: 0.2em #fff;
	}
}

/* nunito-200 - latin-ext_latin */
@font-face {
	font-family: 'Nunito';
	font-style: normal;
	font-weight: 200;
	font-display: swap;
	src: url('/fonts/nunito-v25-latin-ext_latin-200.eot');
	/* IE9 Compat Modes */
	src: local(''),
		url('/fonts/nunito-v25-latin-ext_latin-200.eot?#iefix') format('embedded-opentype'),
		/* IE6-IE8 */
		url('/fonts/nunito-v25-latin-ext_latin-200.woff2') format('woff2'),
		/* Super Modern Browsers */
		url('/fonts/nunito-v25-latin-ext_latin-200.woff') format('woff'),
		/* Modern Browsers */
		url('/fonts/nunito-v25-latin-ext_latin-200.ttf') format('truetype'),
		/* Safari, Android, iOS */
		url('/fonts/nunito-v25-latin-ext_latin-200.svg#Nunito') format('svg');
	/* Legacy iOS */
}

/* nunito-300 - latin-ext_latin */
@font-face {
	font-family: 'Nunito';
	font-style: normal;
	font-weight: 300;
	font-display: swap;
	src: url('/fonts/nunito-v25-latin-ext_latin-300.eot');
	/* IE9 Compat Modes */
	src: local(''),
		url('/fonts/nunito-v25-latin-ext_latin-300.eot?#iefix') format('embedded-opentype'),
		/* IE6-IE8 */
		url('/fonts/nunito-v25-latin-ext_latin-300.woff2') format('woff2'),
		/* Super Modern Browsers */
		url('/fonts/nunito-v25-latin-ext_latin-300.woff') format('woff'),
		/* Modern Browsers */
		url('/fonts/nunito-v25-latin-ext_latin-300.ttf') format('truetype'),
		/* Safari, Android, iOS */
		url('/fonts/nunito-v25-latin-ext_latin-300.svg#Nunito') format('svg');
	/* Legacy iOS */
}

/* poppins-800 - latin-ext_latin */
@font-face {
	font-family: 'Poppins';
	font-style: normal;
	font-weight: 800;
	font-display: swap;
	src: url('/fonts/poppins-v20-latin-ext_latin-800.eot');
	/* IE9 Compat Modes */
	src: local(''),
		url('/fonts/poppins-v20-latin-ext_latin-800.eot?#iefix') format('embedded-opentype'),
		/* IE6-IE8 */
		url('/fonts/poppins-v20-latin-ext_latin-800.woff2') format('woff2'),
		/* Super Modern Browsers */
		url('/fonts/poppins-v20-latin-ext_latin-800.woff') format('woff'),
		/* Modern Browsers */
		url('/fonts/poppins-v20-latin-ext_latin-800.ttf') format('truetype'),
		/* Safari, Android, iOS */
		url('/fonts/poppins-v20-latin-ext_latin-800.svg#Poppins') format('svg');
	/* Legacy iOS */
}

html {
	font-size: 16px;
}

html,
body {
	padding: 0em;
	margin: 0em;
	font-family: -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Oxygen, Ubuntu, Cantarell, Fira Sans, Droid Sans, Helvetica Neue, sans-serif;
}

body {
	hyphens: auto;
	-ms-hyphens: auto;
	-moz-hyphens: auto;
	-webkit-hyphens: auto;
	overflow-x: hidden;
}

.donthyphenate {
	hyphens: none;
	-ms-hyphens: none;
	-moz-hyphens: none;
	-webkit-hyphens: none;
}

a {
	color: inherit;
	text-decoration: none;
}

ol {
	list-style-type: auto;
}

* {
	box-sizing: border-box;
}

#cookie-consent-container .floating-button {
	box-shadow: 0.15em 0.15em 0.6em hsla(0deg, 0%, 0%, 0.1);
	bottom: 1rem;
	left: 1rem;
}

#rocketchat-iframe {
	filter: drop-shadow(0.15em 0.15em 0.6em hsla(0deg, 0%, 0%, 0.1));
}
